<template>
  <div class="HSMask">
    <div class="HSDialog" style="width: 720px;padding: 20px 30px;">
      <div class="HSDialogTitleLine">
        <div class="HSDialogTitle">会员信息导入</div>
        <img class="HSDialogCloseImg" src="../assets/img/HS/close.png" @click="close" />
      </div>
      <div class="HSDialogCom">
        <div class="clb-goods-import-infos">
          <span>1</span>
          <div class="clb-goods-import">
            <h1>下载会员导入模板</h1>
            <h2>下载最新的会员导入模板Excel文件。</h2>
          </div>
        </div>
        <div class="clb-goods-import-infos">
          <span>2</span>
          <div class="clb-goods-import">
            <h1>填写模板信息</h1>
            <h2>根据模板文件要求填写会员信息, 确保模板内填写的会员卡模板与系统中的会员卡模板名称一致。</h2>
          </div>
        </div>
        <div class="clb-goods-import-infos">
          <span>3</span>
          <div class="clb-goods-import">
            <h1>上传文件数据</h1>
            <h2>在右边上传并导入会员信息。</h2>
          </div>
        </div>
        <div class="clb-goods-import-infos">
          <span>4</span>
          <div class="clb-goods-import">
            <h1>确认导入信息</h1>
            <h2>点击【上传文件】后进行信息核对，确认无误即可提交。</h2>
          </div>
        </div>
      </div>
      <div class="HSDialogButtonLine">
        <div class="HSDialogButtonCancel" @click="Drow">下载模版</div>
        <div class="HSDialogButtonConfirm"> <el-upload class="upload-demo" ref="upload" action="#" :limit="1" multiple
            :file-list="fileList" :on-change="handlChange" :auto-upload="false">
            <div style="display: flex; align-items: center;">
              <el-button slot="trigger" size="small" style="background-color: #F77E04;border: none;color: aliceblue;">导入</el-button>
            </div>
          </el-upload></div>
      </div>
    </div>
   
  </div>
</template>
<script>
import { put } from '@/api/upload.js'
export default {
  data() {
    return {
      downloading: false, //跟踪文件是否正在下载
      fileList: [],
      url:'',
      info:[]
    }
  },
  computed: {

  },
  mounted() {
  },
  methods: {
    //下载模版
    Drow() {
      if (this.downloading) return;
      this.downloading = true;
      const link = document.createElement("a");
      link.href = 'https://huashengchong.oss-cn-beijing.aliyuncs.com/template/memberTemplate.xls';
      link.setAttribute("download", "hsc-print.exe");
      document.body.appendChild(link);
      link.click();
      setTimeout(() => {
        document.body.removeChild(link);
        this.downloading = false;
      }, 10000); // 设置节流时间，例如1秒
    },
    // 关闭
    close() {
      this.$emit("submit", { type: "close", data: "" })
    },
    // 提交
    handlChange(file, fileList) {
      console.log(file, fileList, "fileList");
      var fileName = file.name
      var file1 = file.raw
      put(fileName, file1).then((result) => {
        let obj = {
          name: result.name,
          url: result.url
        }
        this.fileList.push(obj)
        this.url = result.url
        let params = {
          fileUrl: this.url
        }
        this.$http.get("/customer/Member/ImportRemoteExcelFile", params).then(res => {
          if (res.code === 0) {
            this.$emit("submit", { type: "success", data: res.data })
          }else if(res.code==-1){
            this.fileList=[]
          }
        })
      });
    },
  
  }
};
</script>
<style lang="scss" scoped>
.HSDialogCom {
  width: 95%;
  margin-bottom: 20px;

  .clb-goods-import-infos {
    // line-height: 20px;
    float: left;
    margin-left: 5%;
    margin-bottom: 20px;
    display: flex;
    align-items: center;

    span {
      padding: 5px 12px;
      font-size: 16px;
      text-align: center;
      color: #333;
      border-radius: 50%;
      border: 1px solid #7f7f7f;
    }

    .clb-goods-import {
      align-items: center;

      h1 {
        margin-left: 2%;
        font-size: 17px;
        color: #333;
        font-weight: 400;
      }

      h2 {
        width: 100%;
        margin-left: 2%;
        font-size: 15px;
        color: #7f7f7f;
        font-weight: 400;
      }
    }
  }
}

.HSDText {
  color: red;
}
</style>
