<template>
	<div class="HSMask">
		<div class="HSDialog" style="width: 1000px;height: 900px;">
			<div class="HSDialogTitleLine">
				<div class="HSDialogTitle">导入文件</div>
				<img class="HSDialogCloseImg" src="../assets/img/HS/close.png" @click="close" />
			</div>
			<div class="HSDialogBtn">
				<el-button :type="tab == 0 ? 'primary' : ''" @click="handleTabClick(0)">正常</el-button>
				<el-button :type="tab == 1 ? 'primary' : ''" @click="handleTabClick(1)">异常</el-button>
			</div>
			<div v-if="tab == 0" >
				<el-table :data="tableObj.list" height="200"
					style="width: 100%;box-sizing: border-box;height:580px;overflow: auto;">
					<el-table-column width="125px" label="会员编号">
						<template slot-scope="scope">
							{{ scope.row.userNo }}
						</template>
					</el-table-column>
					<el-table-column label="昵称">
						<template slot-scope="scope">
							{{ scope.row.nickName }}
						</template>
					</el-table-column>
					<el-table-column label="手机号" width="120px">
						<template slot-scope="scope">
							{{ scope.row.userMobile }}
						</template>
					</el-table-column>
					<el-table-column label="余额">
						<template slot-scope="scope">
							{{ scope.row.userYue }}
						</template>
					</el-table-column>
					<el-table-column label="会员卡号" width="180px">
						<template slot-scope="scope">
							{{ scope.row.cardId }}
						</template>
					</el-table-column>
					<el-table-column label="会员积分">
						<template slot-scope="scope">
							{{ scope.row.userJifen }}
						</template>
					</el-table-column>
					<el-table-column label="创建时间" width="180px">
						<template slot-scope="scope">
							{{ $public.FTime(scope.row.createTime, "YYYY-MM-DD") }}
						</template>
					</el-table-column>
					<el-table-column label="真实姓名">
						<template slot-scope="scope">
							{{ scope.row.realName }}
						</template>
					</el-table-column>
					<el-table-column label="性别">
						<template slot-scope="scope">
							{{ scope.row.sex }}
						</template>
					</el-table-column>
					<el-table-column label="生日">
						<template slot-scope="scope">
							{{ scope.row.birthday }}
						</template>
					</el-table-column>
					<el-table-column label="备注">
						<template slot-scope="scope">
							{{ scope.row.remark }}
						</template>
					</el-table-column>
					<el-table-column label="宠物1昵称">
						<template slot-scope="scope">
							{{ scope.row.petNickName1 }}
						</template>
					</el-table-column>
					<el-table-column label="宠物1种类">
						<template slot-scope="scope">
							{{ scope.row.applyTypeName1 }}
						</template>
					</el-table-column>
					<el-table-column label="宠物1性别">
						<template slot-scope="scope">
							{{ scope.row.petSex1 }}
						</template>
					</el-table-column>
					<el-table-column label="宠物1颜色">
						<template slot-scope="scope">
							{{ scope.row.petColor1 }}
						</template>
					</el-table-column>
					<el-table-column label="宠物1的品种">
						<template slot-scope="scope">
							{{ scope.row.petCategoryName1 }}
						</template>
					</el-table-column>
					<el-table-column label="宠物1出生日期">
						<template slot-scope="scope">
							{{ scope.row.bronDate1 }}
						</template>
					</el-table-column>
					<el-table-column label="宠物1体重">
						<template slot-scope="scope">
							{{ scope.row.weight1 }}
						</template>
					</el-table-column>
					<el-table-column label="宠物1备注信息">
						<template slot-scope="scope">
							{{ scope.row.petRemark1 }}
						</template>
					</el-table-column>

					<el-table-column label="宠物2昵称">
						<template slot-scope="scope">
							{{ scope.row.petNickName2 }}
						</template>
					</el-table-column>
					<el-table-column label="宠物2种类">
						<template slot-scope="scope">
							{{ scope.row.applyTypeName2 }}
						</template>
					</el-table-column>
					<el-table-column label="宠物2性别">
						<template slot-scope="scope">
							{{ scope.row.petSex2 }}
						</template>
					</el-table-column>
					<el-table-column label="宠物2颜色">
						<template slot-scope="scope">
							{{ scope.row.petColor2 }}
						</template>
					</el-table-column>
					<el-table-column label="宠物2的品种">
						<template slot-scope="scope">
							{{ scope.row.petCategoryName2 }}
						</template>
					</el-table-column>
					<el-table-column label="宠物2出生日期">
						<template slot-scope="scope">
							{{ scope.row.bronDate2 }}
						</template>
					</el-table-column>
					<el-table-column label="宠物2体重">
						<template slot-scope="scope">
							{{ scope.row.weight2 }}
						</template>
					</el-table-column>
					<el-table-column label="宠物2备注信息">
						<template slot-scope="scope">
							{{ scope.row.petRemark2 }}
						</template>
					</el-table-column>
					<el-table-column label="是否异常">
						<template slot-scope="scope">
							{{ scope.row.isError ? '异常' : '正常' }}
						</template>
					</el-table-column>
					<el-table-column label="操作">
						<template slot-scope="scope">
							<el-button type="text" size="small" @click="del(scope.$index)">删除</el-button>
						</template>
					</el-table-column>
				</el-table>
			</div>
			<div v-if="tab == 1" >
				<el-table :data="tableObj.errorList"
				height="200"
					style="width: 100%;box-sizing: border-box;height:580px;overflow: auto;">
					<el-table-column label="异常信息">
						<template slot-scope="scope">
							{{ scope.row.errorMsg }}
						</template>
					</el-table-column>
					<el-table-column width="125px" label="会员编号">
						<template slot-scope="scope">
							{{ scope.row.userNo }}
						</template>
					</el-table-column>
					<el-table-column label="昵称">
						<template slot-scope="scope">
							{{ scope.row.nickName }}
						</template>
					</el-table-column>
					<el-table-column label="手机号" width="120px">
						<template slot-scope="scope">
							{{ scope.row.userMobile }}
						</template>
					</el-table-column>
					<el-table-column label="余额">
						<template slot-scope="scope">
							{{ scope.row.userYue }}
						</template>
					</el-table-column>
					<el-table-column label="会员卡号" width="180px">
						<template slot-scope="scope">
							{{ scope.row.cardId }}
						</template>
					</el-table-column>
					<el-table-column label="会员积分">
						<template slot-scope="scope">
							{{ scope.row.userJifen }}
						</template>
					</el-table-column>
					<el-table-column label="创建时间" width="180px">
						<template slot-scope="scope">
							{{ $public.FTime(scope.row.createTime, "YYYY-MM-DD") }}
						</template>
					</el-table-column>
					<el-table-column label="真实姓名">
						<template slot-scope="scope">
							{{ scope.row.realName }}
						</template>
					</el-table-column>
					<el-table-column label="性别">
						<template slot-scope="scope">
							{{ scope.row.sex }}
						</template>
					</el-table-column>
					<el-table-column label="生日">
						<template slot-scope="scope">
							{{ scope.row.birthday }}
						</template>
					</el-table-column>
					<el-table-column label="备注">
						<template slot-scope="scope">
							{{ scope.row.remark }}
						</template>
					</el-table-column>
					<el-table-column label="宠物1昵称">
						<template slot-scope="scope">
							{{ scope.row.petNickName1 }}
						</template>
					</el-table-column>
					<el-table-column label="宠物1种类">
						<template slot-scope="scope">
							{{ scope.row.applyTypeName1 }}
						</template>
					</el-table-column>
					<el-table-column label="宠物1性别">
						<template slot-scope="scope">
							{{ scope.row.petSex1 }}
						</template>
					</el-table-column>
					<el-table-column label="宠物1颜色">
						<template slot-scope="scope">
							{{ scope.row.petColor1 }}
						</template>
					</el-table-column>
					<el-table-column label="宠物1的品种">
						<template slot-scope="scope">
							{{ scope.row.petCategoryName1 }}
						</template>
					</el-table-column>
					<el-table-column label="宠物1出生日期">
						<template slot-scope="scope">
							{{ scope.row.bronDate1 }}
						</template>
					</el-table-column>
					<el-table-column label="宠物1体重">
						<template slot-scope="scope">
							{{ scope.row.weight1 }}
						</template>
					</el-table-column>
					<el-table-column label="宠物1备注信息">
						<template slot-scope="scope">
							{{ scope.row.petRemark1 }}
						</template>
					</el-table-column>

					<el-table-column label="宠物2昵称">
						<template slot-scope="scope">
							{{ scope.row.petNickName2 }}
						</template>
					</el-table-column>
					<el-table-column label="宠物2种类">
						<template slot-scope="scope">
							{{ scope.row.applyTypeName2 }}
						</template>
					</el-table-column>
					<el-table-column label="宠物2性别">
						<template slot-scope="scope">
							{{ scope.row.petSex2 }}
						</template>
					</el-table-column>
					<el-table-column label="宠物2颜色">
						<template slot-scope="scope">
							{{ scope.row.petColor2 }}
						</template>
					</el-table-column>
					<el-table-column label="宠物2的品种">
						<template slot-scope="scope">
							{{ scope.row.petCategoryName2 }}
						</template>
					</el-table-column>
					<el-table-column label="宠物2出生日期">
						<template slot-scope="scope">
							{{ scope.row.bronDate2 }}
						</template>
					</el-table-column>
					<el-table-column label="宠物2体重">
						<template slot-scope="scope">
							{{ scope.row.weight2 }}
						</template>
					</el-table-column>
					<el-table-column label="宠物2备注信息">
						<template slot-scope="scope">
							{{ scope.row.petRemark2 }}
						</template>
					</el-table-column>
				</el-table>
			</div>
			<div class="HSDialogText">
				<div>
					温馨提示：
				</div>
				<div> 余额只能是数字
					日期只能是 日期格式
					请确保手机号是唯一的
					只有添加了宠物的昵称 宠物才能被添加
					请删除空白行数据</div>
				<div>异常数据不会被提交</div>
			</div>
			<div class="HSDialoDown" v-html="info">
			</div>
			<div style="height: 80px;"></div>
			<div class="HSDialogButtonLine">
				<div class="HSDialogButtonCancel" @click="close">取消</div>
				<div class="HSDialogButtonConfirm" @click="submit">确认</div>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	data() {
		return {
			tab: 0,
			info: ''
		}
	},
	props: {
		tableObj: {
			default: () => {
				return {}
			}
		}
	},
	computed: {

	},
	mounted() {
		console.log(this.tableObj, 'tableObjtableObj');
	},
	methods: {
		// 删除
		del(index) {
			let list = [...this.tableObj.list];
			list.splice(index, 1);
			this.tableObj.list = list;
		},
		//  切换
		handleTabClick(i) {
			this.tab = i
		},
		// 关闭
		close() {
			this.$emit("submit", { type: "close", data: "" })
			this.info = ''
		},
		// 提交
		submit() {
			this.$http.post("/customer/Member/UploadMemberInfo", this.tableObj.list).then(res => {
				if (res.code === 0) {
					console.log(res, 'res');
					this.info = res.info
					// this.$emit("submit", { type: "success", data: res.data })
				} else {
					// this.fileList=[]
					this.info = res.info
					console.log(res.info);
				}
			})
			// this.$emit("submit", { type: 'success', data: "" })
		},

	}
};
</script>
<style lang="scss" scoped>
.HSDialogCom {
	// flex-wrap: wrap;
}

.HSDialogBtn {
	display: flex;
	margin-left: 30px;
}

.HSDialogText {
	margin-left: 20px;
	font-size: 14px;
}

.HSDialoDown {
	width: 100%;
	padding: 20px 30px;
	box-sizing: border-box;
	height: 100px;
	overflow: auto;
	font-size: 13px;
	color: red;
}

.HSDText {
	color: red;
}
</style>
