<template>
	<div class="page">
		<!-- 搜索栏 -->
		<div class="tabView">
			<div class="tabViewL">
				<div class="search">
					<el-input v-model="keyword" @keyup.enter.native="toSearch" placeholder="请输入关键字"
						style="width: 150px;margin-right: 10px;">
					</el-input>
					<el-button type="primary" style="margin-right: 22px;" @click="toSearch">查询</el-button>
				</div>
				<div class="search">
					<div class="searchTitle">会员性别</div>
					<el-select v-model="sex" @change="toSearch" placeholder="请选择" style="width: 143px;">
						<el-option v-for="(item, index) in [{ label: '全部', value: '-1' }, { label: '男', value: '1' }, { label: '女', value: '0' }]"
							:key="index" :label="item.label" :value="item.value"></el-option>
					</el-select>
				</div>
				<div class="search">
					<div class="searchTitle">宠物类别</div>
					<el-select v-model="applyType" @change="toSearch" placeholder="请选择" style="width: 143px;">
						<el-option
							v-for="(item, index) in [{ label: '全部', value: '' }, { label: '猫猫', value: 'cat' }, { label: '狗狗', value: 'dog' }, { label: '其他', value: 'yichong' }]"
							:key="index" :label="item.label" :value="item.value"></el-option>
					</el-select>
				</div>
			</div>
			<div class="tabViewR">
				<el-button type="primary" @click="dialogAddUserAndPet_state = true">新建会员</el-button>
				<el-button type="primary" v-if="$buttonAuthority('huiyuan-huiyuanList-batch') " @click="dialogUserExport_state = true">批量新增会员</el-button>
				<el-button @click="reset">重置</el-button>
			</div>
		</div>
		<!-- 内容信息 -->
		<div class="view">
			<el-table :data="tableData" height="100%">
				<el-table-column min-width="100" label="姓名">
					<template slot-scope="scope">
						{{ scope.row.basicInfo.nickName }}
					</template>
				</el-table-column>
				<el-table-column width="80" label="性别">
					<template slot-scope="scope">
						{{ scope.row.basicInfo.sex }}
					</template>
				</el-table-column>
				<el-table-column min-width="100" label="电话">
					<template slot-scope="scope">
						{{ scope.row.basicInfo.userMobile }}
					</template>
				</el-table-column>
				<el-table-column min-width="100" label="宠物">
					<template slot-scope="scope">
						<div v-if="!$public.isNull(scope.row.pets_info)">
							<div v-for="item in scope.row.pets_info" :key="item.id">
								<span> {{ item.nickName }}({{ item.categoryInfo.name }})<img v-if="item.vipPet" class="VipImg"
										src="../../../assets/img/shouyin/VIP.png" /> </span>
							</div>
						</div>
					</template>
				</el-table-column>
				<el-table-column min-width="100" label="余额">
					<template slot-scope="scope">
						{{ scope.row.cardInfo.userYue }}
					</template>
				</el-table-column>
				<el-table-column width="110" label="注册时间">
					<template slot-scope="scope">
						{{ $public.FTime(scope.row.operationInfo.create.handleTimeStamp, "YYYY-MM-DD") }}
					</template>
				</el-table-column>
				<el-table-column min-width="100" label="操作">
					<template slot-scope="scope">
						<el-button type="text" size="small" v-if="$buttonAuthority('huiyuan-huiyuanList-goBuy')"
							@click="$router.push({ path: '/mendian/shouyin?uid=' + scope.row.id })">去下单</el-button>
						<el-button type="text" size="small" v-if="$buttonAuthority('huiyuan-huiyuanList-see')"
							@click="$router.push({ path: '/huiyuan/huiyuanSee?id=' + scope.row.id })">查看</el-button>
					</template>
				</el-table-column>
			</el-table>
			<div style="width:100%;position: absolute;bottom: 0;right: 0;">
				<page :pageSizes="pageSizes" @size-change="handleSizeChange" :pageIndex="page.pageIndex"
					:pageSize="page.pageSize" :total="page.total" @change="changePage"></page>
			</div>
		</div>
		<div v-if="dialogAddUserAndPet_state">
			<dialogAddUserAndPet @submit="submit_dialogAddUserAndPet"></dialogAddUserAndPet>
		</div>
		<div v-if="dialogUserExport_state">
			<dialogUserExport @submit="submit_dialogUserExport"></dialogUserExport>
		</div>
		<div v-if="dialogExport_state">
			<dialogExport :tableObj="tableObj" @submit="submit_dialogExport"></dialogExport>
		</div>
	</div>	
</template>
<script>
import dialogAddUserAndPet from "../../../components/dialogAddUserAndPet.vue"
import dialogUserExport from "../../../components/dialogUserExport.vue"
import dialogExport from "../../../components/dialogExport.vue"
import page from "../../../components/page.vue"
export default {
	components: { page, dialogAddUserAndPet, dialogUserExport, dialogExport },
	data() {
		return {
			// 搜索
			keyword: '',
			grades: '-1',
			sex: '-1',
			applyType: '',
			// table
			page: {
				pageIndex: 1,
				pageSize: 10,
				total: 0,
			},
			tableObj:{},
			dialogExport_state: false,
			pageSizes: [10, 15, 30, 60],
			tableData: [],
			// 添加顾客和宠物
			dialogAddUserAndPet_state: false,
			dialogUserExport_state: false
		}
	},
	computed: {
		memberStarList() {
			let list = this.$store.state.dictionary.memberStarList
			if (list.length > 0) {
				list.unshift({ name: "全部", value: '-1' })
				return list
			} else {
				return []
			}
		},
	},
	mounted() {
		this.$store.commit("getMemberStarList")
		this.getTableData();
	},
	methods: {
		submit_dialogExport() {
			// if(obj.type==='success'){

			// }
			this.dialogExport_state = false;
		},
		// 导入
		submit_dialogUserExport(obj) {
			if (obj.type === 'success') {
				console.log(obj,'obj');
				this.tableObj=obj.data
				this.dialogExport_state = true;
			}
			this.dialogUserExport_state = false;
		},
		// 添加顾客和宠物
		submit_dialogAddUserAndPet() {
			this.dialogAddUserAndPet_state = false;
			this.getTableData();
		},
		// 执行搜索
		toSearch() {
			this.page.pageIndex = 1;
			this.getTableData()
		},
		reset() {
			this.keyword = ''
			this.grades = '-1'
			this.sex = '-1'
			this.applyType = ''
			this.page.pageIndex = 1;
			this.getTableData()
		},
		changePage(num) {
			this.page.pageIndex = num;
			this.getTableData()
		},
		handleSizeChange(val) {
			this.page.pageIndex = 1;
			this.page.pageSize = val
			this.getTableData()
		},
		getTableData() {
			let params = {
				"sex": Number(this.sex),  // 性别 -1：全部、 0：女、1：男
				"applyType": this.applyType, // 宠物类别 犬类；猫猫类
				"startTime": "",
				"endTime": "",
				"keyword": this.keyword,
				"pageIndex": this.page.pageIndex,
				"pageSize": this.page.pageSize,
			}
			let loading = this.$loading()
			this.$http.post("/customer/Member/List", params).then(res => {
				loading.close()
				if (res.code === 0) {
					this.tableData = res.data.list
					this.page.total = res.data.count
				}
			})
			// this.$http.get("/cms/System/GetSystemDictionList",{
			// 	companyId:"654321"
			// }).then(res=>{
			// 	console.log(res)
			// })
		}
	}
}
</script>
<style lang="scss" scoped>
.page {
	width: 100%;
	height: 100%;
	color: $fontColor;

	// 搜索信息
	.tabView {
		height: 80px;
		border-radius: 20px;
		background: #fff;
		margin-bottom: 16px;
		display: flex;
		justify-content: space-between;
		width: 100%;
		padding: 0 20px;
		box-sizing: border-box;

		.tabViewL {
			.search {
				margin-right: 12px;
				display: inline-flex;
				padding: 20px 0;

				.searchTitle {
					font-size: 14px;
					width: 60px;
					color: $fontColor;
					line-height: 40px;
				}
			}
		}

		.tabViewR {
			padding-top: 20px;
		}
	}

	// 内容信息
	.view {
		position: relative;
		height: calc(100% - 96px);
		padding-bottom: 52px;
		box-sizing: border-box;
		width: 100%;
		border-radius: 20px;
		overflow: hidden;
	}
}
</style>
